import {
  createSelector,
  createAsyncThunk,
  createSlice /*createSelector*/ /*, current */,
} from "@reduxjs/toolkit";
import { fetchSiteMacChartData, fetchSiteImsiChartData } from "../../api/api";

/** Initial State */
const initialState = {
  macChartDataLoading: true,
  imsiChartDataLoading: true,
  TotalMacsData: [],
  TotalImsiData: [],
  TotalDistinctMacsData: [],
  TotalDistinctImsiData: [],
  TotalBTData: [],
  TotalWIFIData: [],
};

/** Slice */
export const {
  reducer: chart,
} = createSlice({
  name: "chart",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(setMacChartData.pending, (state) => {
      state.macChartDataLoading = true;
    });
    builder.addCase(setMacChartData.fulfilled, (state, action) => {
      // console.log("setMacChartData.fulfilled", action.payload);
      state.macChartDataLoading = false;
      if (action.payload) {
        const sortedPayload = action.payload.sort((a, b) => a.start.localeCompare(b.start));
        state.TotalMacsData = sortedPayload.map((value) => ({
          label: value.start.substring(0, 10),
          value: value.totalCount,
        }));
        state.TotalDistinctMacsData = sortedPayload.map((value) => ({
          label: value.start.substring(0, 10),
          value: value.totalCountDistinct,
        }));
        state.TotalBTData = sortedPayload.map((value) => ({
          label: value.start.substring(0, 10),
          value: value.totalBT,
        }));
        state.TotalWIFIData = sortedPayload.map((value) => ({
          label: value.start.substring(0, 10),
          value: value.totalWIFI,
        }));
      } else {
        state.TotalMacsData = [];
        state.TotalDistinctMacsData = [];
        state.TotalBTData = [];
        state.TotalWIFIData = [];
      }
    });
    builder.addCase(setImsiChartData.pending, (state) => {
      state.imsiChartDataLoading = true;
    });
    builder.addCase(setImsiChartData.fulfilled, (state, action) => {
      // console.log("setImsiChartData.fulfilled", action.payload);
      state.imsiChartDataLoading = false;
  
      if (action.payload) {
        const sortedPayload = action.payload.sort((a, b) => a.start.localeCompare(b.start));
        state.TotalImsiData = sortedPayload.map((value) => ({
          label: value.start.substring(0, 10),
          value: value.totalCount,
        }));
        state.TotalDistinctImsiData = sortedPayload.map((value) => ({
          label: value.start.substring(0, 10),
          value: value.totalCountDistinct,
        }));
      } else {
        state.TotalImsiData = [];
        state.TotalDistinctImsiData = [];
      }

      // console.log("TotalImsiData", state.TotalImsiData);
    });
  },
});

/** Selectors */
export const macChartDataLoadingSel = ({ chart }) => chart.macChartDataLoading;
export const imsiChartDataLoadingSel = ({ chart }) =>
  chart.imsiChartDataLoading;
export const TotalMacsDataSel = ({ chart }) => chart.TotalMacsData;
export const TotalImsiDataSel = ({ chart }) => chart.TotalImsiData;
export const TotalDistinctMacsDataSel = ({ chart }) =>
  chart.TotalDistinctMacsData;
export const TotalDistinctImsiDataSel = ({ chart }) =>
  chart.TotalDistinctImsiData;
export const TotalBTDataSel = ({ chart }) => chart.TotalBTData;
export const TotalWIFIDataSel = ({ chart }) => chart.TotalWIFIData;

export const chartDataSel = createSelector(
  TotalMacsDataSel,
  TotalImsiDataSel,
  TotalDistinctMacsDataSel,
  TotalDistinctImsiDataSel,
  TotalBTDataSel,
  TotalWIFIDataSel,
  (
    TotalMacsData,
    TotalImsiData,
    TotalDistinctMacsData,
    TotalDistinctImsiData,
    TotalBTData,
    TotalWIFIData,
  ) => {
    return {
      TotalMacsData,
      TotalImsiData,
      TotalDistinctMacsData,
      TotalDistinctImsiData,
      TotalBTData,
      TotalWIFIData,
    };
  },
);

/**
 * Side Effects (THUNKS)
 * */
export const setMacChartData = createAsyncThunk(
  "chart/setMacChartData",
  async ({ selectedStartDate, selectedEndDate }, thunkApi) => {
    const siteGraph = thunkApi.getState()?.dashboard?.siteGraph;
    const filteredSiteGraph = siteGraph.filter(
      (item) => item.itemSelected === true,
    );
    const siteList =
      siteGraph
        ? filteredSiteGraph.map((item) => item.wgSiteId)
        : [];

    // console.log("siteList", siteList.toString());
    const chartPromise = fetchSiteMacChartData(
      siteList.toString(),
      selectedStartDate,
      selectedEndDate,
    );

    return Promise.resolve(chartPromise).then((value) => value.data);
  },
);

export const setImsiChartData = createAsyncThunk(
  "chart/setImsiChartData",
  async ({ selectedStartDate, selectedEndDate }, thunkApi) => {
    const siteGraph = thunkApi.getState()?.dashboard?.siteGraph;
    const filteredSiteGraph = siteGraph.filter(
      (item) => item.itemSelected === true,
    );

    const siteList =
      siteGraph
        ? filteredSiteGraph.map((item) => item.wgSiteId)
        : [];
    const chartPromise = fetchSiteImsiChartData(
      siteList.toString(),
      selectedStartDate,
      selectedEndDate
    );

    return Promise.resolve(chartPromise).then((value) => value.data);
  },
);
