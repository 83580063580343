export const wgLabApiConfig = {
  wgLabBaseURL: `${process.env.REACT_APP_WG_LAB_BASE_URL}`,
  wgLabApiKey: `${process.env.REACT_APP_WG_LAB_API_KEY}`,
};

export const mondayConfig = {
  baseMondayURL: `${process.env.REACT_APP_MONDAY_BASE_URL}`,
  authURL: `${process.env.REACT_APP_MONDAY_AUTH_URL}`,
  apiKey: `${process.env.REACT_APP_MONDAY_API_KEY}`,
};

export const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};
