import axios from 'axios';

import { wgLabApiConfig, mondayConfig } from './api_config';

const USER_ADMIN_BOARD_ID = 5518218110;

const MONDAY_POST_HEADERS = {
  'Content-Type': 'application/json',
  'API-Version': '2023-10',
  Authorization: `bearer ${mondayConfig.apiKey}`,
};

export const postRegisteredUser = async ({ userName, customerName, uid, emailAddress }) => {
  const [date, time] = new Date().toISOString().split('T');
  const colVals = JSON.stringify({
    text6: customerName,
    text: uid,
    text0: window.location.hostname,
    email: { email: emailAddress, text: emailAddress },
    date4: { date, time: time.split('.')[0] },
  });
  /* eslint-disable */
  // linter disabled to avoid warnings about escape chars
  const data = {
    query: `mutation {
      create_item (
        board_id: ${USER_ADMIN_BOARD_ID},
        item_name: "${userName}",
        column_values: "${colVals.replaceAll('"', '\\\"')}"
      ) {
        id
      }
    }`,
  };
  /* eslint-enable */
  return await axios.post(mondayConfig.authURL, data, { headers: MONDAY_POST_HEADERS });
};



export const fetchSiteIds = async (user) => {
  const data = {
    query: `query {
      items_page_by_column_values(
        limit: 1
        board_id: 5518218110
        columns: [
          {column_id: "email", column_values: ["${user?.email}"]},

        ]
      ) {
        items {
          id
          name
          column_values(ids: ["connect_boards0"]) {
            id
            value
            text
          }
        }
      }
    }`,
  };
  // console.log(MONDAY_POST_HEADERS)
  // console.log(mondayConfig.authURL)
  return await axios.post(mondayConfig.authURL, data, { headers: MONDAY_POST_HEADERS });
};

export const fetchSiteList = async (siteIds) => {
  // console.log(siteIds)
  const data = {
    query: `query {
      items(ids: [${siteIds}]) {
        column_values(ids: ["name", "text25"]) {
          value
        }
      }
    }`,
  };

  return await axios.post(mondayConfig.authURL, data, { headers: MONDAY_POST_HEADERS });
};

export const fetchSiteGraphData = async (siteId) => {
  const requestHeaders = {
    'Content-Type': 'application/json',
    'X-API-KEY': wgLabApiConfig.wgLabApiKey,
    Accept: '*/*',
  };
  const reqData = siteId;
  return await axios.post(
    wgLabApiConfig.wgLabBaseURL + "/api/v1/sites/search/mondayIds",
    reqData,
    {
      headers: requestHeaders,
    },
  );
};

export const fetchSiteMacData = async (siteId, dateFrom, dateTo) => {
  const requestHeaders = {
    'Content-Type': 'application/json',
    'X-API-KEY': wgLabApiConfig.wgLabApiKey,
    Accept: '*/*',
  };
    if (dateFrom === dateTo) {
      dateTo = ""
    }

  const reqData = siteId
  const params = {
    time: dateFrom,
    timeMax: dateTo,
    
  };
  return await axios.post(wgLabApiConfig.wgLabBaseURL + '/api/v1/macdata/report/list', reqData, {
    headers: requestHeaders,
    params: params,
  });
};

export const fetchSiteMacChartData = async (siteId, dateFrom, dateTo) => {
  const requestHeaders = {
    "Content-Type": "application/json",
    "X-API-KEY": wgLabApiConfig.wgLabApiKey,
    Accept: "*/*",
  };
  if (dateFrom === dateTo) {
    dateTo = "";
  }

  const reqData = siteId.replace(/"/g, '');

  // console.log(reqData)
  const params = {
    time: dateFrom,
    timeMax: dateTo,
    chart: "true",
  };
  return await axios.post(
    wgLabApiConfig.wgLabBaseURL + "/api/v1/macdata/report/list",
    reqData,
    {
      headers: requestHeaders,
      params: params,
    },
  );
};


export const fetchSiteImsiData = async (siteId, dateFrom, dateTo) => {
  const requestHeaders = {
    'X-API-KEY': wgLabApiConfig.wgLabApiKey,
    Accept: '*/*',
    'Content-Type': 'application/json',
  };
  if (dateFrom === dateTo) {
    dateTo = ""
  }
  const reqData = siteId.replace(/"/g, "");
  // console.log(reqData)
  const params = {
    time: dateFrom,
    timeMax: dateTo,
  };
  return await axios.post(wgLabApiConfig.wgLabBaseURL + '/api/v1/imsidata/report/list', reqData, {
    headers: requestHeaders,
    params: params,
  });
};

export const fetchSiteImsiChartData = async (siteId, dateFrom, dateTo) => {
  const requestHeaders = {
    "X-API-KEY": wgLabApiConfig.wgLabApiKey,
    Accept: "*/*",
    "Content-Type": "application/json",
  };
  if (dateFrom === dateTo) {
    dateTo = "";
  }
  const reqData = siteId.replace(/"/g, "");
  // console.log(reqData)
  const params = {
    time: dateFrom,
    timeMax: dateTo,
    chart: "true",
  };
  return await axios.post(
    wgLabApiConfig.wgLabBaseURL + "/api/v1/imsidata/report/list",
    reqData,
    {
      headers: requestHeaders,
      params: params,
    },
  );
};


export const fetchCompanies = async () => {
  const requestHeaders = {
    'Content-Type': 'application/json',
    'X-API-KEY': wgLabApiConfig.wgLabApiKey,
    Accept: '*/*',
  };
  return await axios.get(wgLabApiConfig.wgLabBaseURL + '/api/v1/companies/search?pageSize=1500', {
    headers: requestHeaders,
    });
  } // Add this closing curly brace
