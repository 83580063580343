const today = new Date();

export const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'full',
  timeStyle: undefined,
});



export const endDate = today;
export const startDate = new Date(today);
// export const ReportDate = startDate.toISOString().split("T")[0];
// export const ReportDateMax = today.toISOString().split("T")[0];
startDate.setDate(today.getDate() - 7);
// console.log('startDate', startDate);
// console.log('endDate', endDate);

export const ReportDateDisplayString = dateFormatter.format(startDate);
// export const ReportDate = startDate.toISOString().split('T')[0];
// export const ReportDateMax = endDate.toISOString().split('T')[0];

export const formatNumber = (number) => {
  const nf = new Intl.NumberFormat();
  return nf?.format(number);
};
