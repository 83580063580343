import {
  createSelector,
  createAsyncThunk,
  createSlice /*createSelector*/ /*, current */,
} from "@reduxjs/toolkit";
import { startDate, endDate } from "../../utils/utils";
import {
  fetchSiteIds,
  fetchSiteGraphData,
  fetchSiteMacData,
  fetchSiteImsiData,
} from "../../api/api";

/**
 * Initial State
 * */
const initialState = {
  apiLoading: false,
  loadingError: null,
  registrationStatus: null,
  currentStartDate: startDate?.toString(),
  currentEndDate: endDate?.toString(),
  siteCount: 0,
  // async data
  siteList: [],
  siteListLoading: true,
  siteGraph: [],
  siteGraphLoading: true,
  macData: [],
  macDataLoading: true,
  imsiData: [],
  imsiDataLoading: true,
};

/**
 * Slice
 * */
export const {
  actions: {
    setLoadingError,
    setregistrationStatus,
    setCurrentStartDate,
    setCurrentEndDate,
    unSetRegistrationStatus,
    setSiteList,
    setSiteGraph,
    setMacData,
    setImsiData,
    filterList,
  },
  reducer: dashboard,
} = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoadingError(draft, action) {
      draft.loadingError = action.payload;
    },
    setCurrentStartDate(draft, action) {
      draft.currentStartDate = action.payload;
    },
    setCurrentEndDate(draft, action) {
      draft.currentEndDate = action.payload;
    },

    unSetRegistrationStatus(draft) {
      draft.registrationStatus = null;
    },

    filterList(draft, action) {
      const filterSiteGraph = draft.siteGraph.map((site) => {
        if (action.payload.length === 0) {
          return {
            ...site,
            itemSelected: true,
          };
        } else {
          const matchedItem = action.payload.find(
            (item) => item.wgSiteId === site.wgSiteId,
          );
          if (matchedItem) {
            return {
              ...site,
              itemSelected: true,
            };
          } else {
            return {
              ...site,
              itemSelected: false,
            };
          }
        }
      });
      // console.log(filterSiteGraph);
      draft.siteGraph = [...filterSiteGraph];
      draft.siteCount = filterSiteGraph.filter((item) => item.itemSelected).length
    },
  },
  extraReducers: (builder) => {
    /* loadSiteList */
    builder.addCase(loadSiteList.pending, (state) => {
      state.siteListLoading = true;
      state.siteGraphLoading = true;
      state.registrationStatus = null;
    });
    builder.addCase(loadSiteList.fulfilled, (state, action) => {
      if (action.payload[0] === "UNREGISTERD USER") {
        // console.log(action.payload[0]);
        state.registrationStatus = false;
        state.siteListLoading = false;
      } else {
        state.siteList = action.payload.map((item) => item.wgSiteId);
        state.siteGraph = action.payload;
        state.registrationStatus = true;
        state.siteCount = action.payload?.length;
        state.siteListLoading = false;
        state.siteGraphLoading = false;
        // console.log("Site List", state.siteList);
      }
    });

    /* loadSiteGraph */
    builder.addCase(loadSiteGraph.pending, (state) => {
      state.siteGraphLoading = true;
    });
    builder.addCase(loadSiteGraph.fulfilled, (state, action) => {
      const results = action?.payload?.map((data) => ({
        ...data,
        itemSelected: true,
      }));
      state.siteGraph = results;
      state.siteGraphLoading = false;
      // console.log("Site Graph", state.siteGraph);
    });

    /* loadMacData */
    builder.addCase(loadMacData.pending, (state) => {
      state.macDataLoading = true;
    });
    builder.addCase(loadMacData.fulfilled, (state, action) => {
      state.macData = action.payload || [];
      state.macDataLoading = false;
      // console.log("Mac Data", state.macData);
    });

    /* loadImsiData */
    builder.addCase(loadImsiData.pending, (state) => {
      state.imsiDataLoading = true;
    });
    builder.addCase(loadImsiData.fulfilled, (state, action) => {
      state.imsiData = action.payload || [];
      state.imsiDataLoading = false;
      // console.log("Imsi Data", state.imsiData);
    });
  },
});

/**
 * Base Selectors
 * */
export const siteListLoadingSel = ({ dashboard }) => dashboard.siteListLoading;
export const siteGraphLoadingSel = ({ dashboard }) =>
  dashboard.siteGraphLoading;
export const macDataLoadingSel = ({ dashboard }) => dashboard.macDataLoading;
export const imsiDataLoadingSel = ({ dashboard }) => dashboard.imsiDataLoading;

export const currentStartDateSel = ({ dashboard }) =>
  dashboard.currentStartDate;
export const currentEndDateSel = ({ dashboard }) => dashboard.currentEndDate;
export const registrationStatusSel = ({ dashboard }) =>
  dashboard.registrationStatus;

export const siteCountSel = ({ dashboard }) => dashboard.siteCount;
export const siteListSel = ({ dashboard }) => dashboard.siteList;
export const siteGraphSel = ({ dashboard }) => dashboard.siteGraph;
export const macDataSel = ({ dashboard }) => dashboard.macData;
export const imsiDataSel = ({ dashboard }) => dashboard.imsiData;

/**
 * Memo Selectors
 * */
export const graphSelector = createSelector(
  [siteGraphSel, macDataSel, imsiDataSel],
  (graph, mac, imsi) => {
    const listAll = graph.flatMap((item) => {
      return {
        ...item,
        macTotal:
          mac?.filter((row) => row?.wgSiteId === item?.wgSiteId)?.[0]
            ?.totalCount || 0,
        macTotalDistinct:
          mac?.filter((row) => row?.wgSiteId === item?.wgSiteId)?.[0]
            ?.totalCountDistinct || 0,
        macTotalBT:
          mac?.filter((row) => row?.wgSiteId === item?.wgSiteId)?.[0]
            ?.totalBT || 0,
        macTotalWIFI:
          mac?.filter((row) => row?.wgSiteId === item?.wgSiteId)?.[0]
            ?.totalWIFI || 0,
        imsiTotal:
          imsi?.filter((row) => row?.wgSiteId === item?.wgSiteId)?.[0]
            ?.totalCount || 0,
        imsiTotalDistinct:
          imsi?.filter((row) => row?.wgSiteId === item?.wgSiteId)?.[0]
            ?.totalCountDistinct || 0,
      };
    });
    return listAll;
  },
);

const addTotals = (s, n) => s + n;

export const kpiSelector = createSelector(
  [siteGraphSel, macDataSel, imsiDataSel, ],
  (graph, mac, imsi) => {
    const listVisible = graph.filter((item) => item.itemSelected === true);
    const filteredGraph = listVisible?.flatMap((item) => {
      return {
        ...item,
        macTotal:
          mac?.filter((row) => row?.wgSiteId === item.wgSiteId)?.[0]
            ?.totalCount ?? 0,
        macTotalDistinct:
          mac?.filter((row) => row?.wgSiteId === item.wgSiteId)?.[0]
            ?.totalCountDistinct ?? 0,
        macTotalBT:
          mac?.filter((row) => row?.wgSiteId === item.wgSiteId)?.[0]?.totalBT ??
          0,
        macTotalWIFI:
          mac?.filter((row) => row?.wgSiteId === item.wgSiteId)?.[0]
            ?.totalWIFI ?? 0,
        imsiTotal:
          imsi?.filter((row) => row?.wgSiteId === item.wgSiteId)?.[0]
            ?.totalCount ?? 0,
        imsiTotalDistinct:
          imsi?.filter((row) => row?.wgSiteId === item.wgSiteId)?.[0]
            ?.totalCountDistinct ?? 0,
      };
    });

    const aadt = filteredGraph.flatMap((x) => x.aadt ?? 0).reduce(addTotals, 0);

    const macTotal = filteredGraph
      .map((x) => x?.macTotal ?? 0)
      .reduce(addTotals, 0);
    const macTotalDistinct = filteredGraph
      .map((x) => x?.macTotalDistinct ?? 0)
      .reduce(addTotals, 0);

    const imsiTotal = filteredGraph
      .map((x) => x?.imsiTotal ?? 0)
      .reduce(addTotals, 0);
    const imsiTotalDistinct = filteredGraph
      .map((x) => x?.imsiTotalDistinct ?? 0)
      .reduce(addTotals, 0);



    return {
      aadt,
      macTotal,
      macTotalDistinct,
      macTotalDelta: aadt - macTotal,
      imsiTotal,
      imsiTotalDistinct,
      imsiTotalDelta: aadt - imsiTotal,
    };
  },
);

/**
 * Side Effects (THUNKS)
 * */
export const loadSiteList = createAsyncThunk(
  "dashboard/loadSiteList",
  async (user) => {
    /* Load site ids */

    const { data: siteIdData } = await fetchSiteIds(user);
    let uniqueSiteIdsMapped = [];

    if (!!siteIdData?.data?.items_page_by_column_values?.items[0] === false) {
      // console.log(siteIdData);
      return ["UNREGISTERD USER"];
    }
    const columnItems =
      siteIdData?.data?.items_page_by_column_values?.items[0]?.column_values[0]
        ?.value;

    if (JSON.parse(columnItems)?.linkedPulseIds) {
      const linkedPulesIds =
        columnItems &&
        JSON.parse(columnItems)?.linkedPulseIds.map(
          (item) => item.linkedPulseId,
        );

      /* Load site list */
      const { data: siteListData } = await fetchSiteGraphData(
        linkedPulesIds?.toString(),
      );

      uniqueSiteIdsMapped = siteListData;
    }

    return uniqueSiteIdsMapped;
  },
);

export const loadSiteGraph = createAsyncThunk(
  "dashboard/loadSiteGraph",
  async (_, thunkApi) => {
    const siteList = thunkApi.getState()?.dashboard?.siteList;
    const graphPromises = siteList?.map((id) => fetchSiteGraphData(id));
    return Promise.all(graphPromises)
      .then((data) => data.flatMap((item) => item.data))
      .then((result) => result);
  },
);

export const loadMacData = createAsyncThunk(
  "dashboard/loadMacData",
  async ({ selectedStartDate, selectedEndDate }, thunkApi) => {
    const siteList = thunkApi.getState()?.dashboard?.siteList;
    const macPromise = fetchSiteMacData(
      siteList.toString(),
      selectedStartDate,
      selectedEndDate,
    );

    return Promise.resolve(macPromise).then((value) => value.data);
  },
);

export const loadImsiData = createAsyncThunk(
  "dashboard/loadImsiData",
  async ({ selectedStartDate, selectedEndDate }, thunkApi) => {
    // console.log(selectedStartDate, selectedEndDate);
    const siteList = thunkApi.getState()?.dashboard?.siteList;
    // console.log(siteList);
    const imsiPromise = fetchSiteImsiData(
      siteList.toString(),
      selectedStartDate,
      selectedEndDate,
    );
    return Promise.resolve(imsiPromise).then((value) => value.data);
  },
);
