import {
  createAsyncThunk,
  createSlice /*createSelector*/ /*, current */,
} from "@reduxjs/toolkit";
import { fetchCompanies } from "../../api/api";
/** Initial State */
const initialState = {
 companyListLoading: true,
 companyList: []
};
/** Slice */
export const {
  actions: { toggleSidebar, toggleUnfoldSidebar },
  reducer: companyDropdown,
} = createSlice({
  name: "chart",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(loadCompanyList.pending, (state) => {
      state.companyListLoading = true;
    }
    );
    builder.addCase(loadCompanyList.fulfilled, (state, action) => {
      state.companyListLoading = false;
      console.log("loadCompanyList.fulfilled", action.payload);
    state.companyList = action.payload.map(company => ({ label: company.companyName, value: company.companyName }));
    }
    );
  },
});


/** Selectors */

export const companyListSel = (state) => state.companyDropdown.companyList;
export const companyListLoadingSel = (state) => state.companyDropdown.companyListLoading;

/** Async */

export const loadCompanyList = createAsyncThunk(
    "companyDropdown/loadCompanyList",
    async (user) => {
        const response = await fetchCompanies();
        return response.data;
    }
    );