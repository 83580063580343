import React from 'react';
import { Route, Routes, Navigate, HashRouter } from 'react-router-dom';
import './scss/style.scss';

const LoadingWithOverlay = React.lazy(() => import('./components/LoadingWithOverlay'));
const RegisterConfirm = React.lazy(() => import('./pages/register/RegisterConfirm'));
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./pages/login/Login'));
const Register = React.lazy(() => import('./pages/register/Register'));
const Reset = React.lazy(() => import('./pages/reset/Reset'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));

export const App = () => (
  <HashRouter>
    <React.Suspense fallback={<LoadingWithOverlay />}>
      <Routes>
        <Route exact path="/" element={<DefaultLayout />}>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/dashboard" element={<Dashboard withCharts={false} />} />
        </Route>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/reset" name="Register Page" element={<Reset />} />
        <Route
          exact
          path="/register-confirm"
          name="Register Confirmation"
          element={<RegisterConfirm />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </React.Suspense>
  </HashRouter>
);
