import { createSlice /*createSelector*/ /*, current */ } from '@reduxjs/toolkit';

/** Initial State */
const initialState = {
  sidebarShow: false,
  sidebarUnfolded: false,
};

/** Slice */
export const {
  actions: { toggleSidebar, toggleUnfoldSidebar },
  reducer: sidebar,
} = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(draft, action) {
      draft.sidebarShow = action.payload;
    },
    toggleUnfoldSidebar(draft, action) {
      draft.sidebarUnfolded = action.payload;
    },
  },
});

/** Selectors */
export const sidebarUnfolded = ({ sidebar }) => sidebar.sidebarUnfolded;
export const sidebarShow = ({ sidebar }) => sidebar.sidebarShow;
