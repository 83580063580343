import { configureStore } from '@reduxjs/toolkit';

import { sidebar } from './reducers/sidebar';
import { dashboard } from './reducers/dashboard';
import { chart } from './reducers/chart';
import { companyDropdown } from './reducers/companyDropdown';

export const store = configureStore({
  reducer: {
    dashboard: dashboard,
    sidebar: sidebar,
    chart: chart,
    companyDropdown: companyDropdown, 
  },
});
